import React from 'react'
import Head from '../components/Head'

const NotFoundPage = () => {
    return (<>
        <Head title='404 not found - Coach Training EDU' description='404'/>
        <h1 className='5xl'>Page not found</h1>
    </>)
}

export default NotFoundPage